import MarkerClusterer from '@google/markerclustererplus';
import { Field, Form, Formik } from 'formik';
import { position, rem } from 'polished';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { withTheme } from 'styled-components';

import styles from 'helpers/mapstyles';
import { storeType } from 'helpers/prop-types';
import clusterSBF from 'assets/images/cluster-sbf.svg';
import clusterVielLeicht from 'assets/images/cluster-vielLeicht.svg';
import { mq } from 'helpers/stylehelpers';
import { layerable } from 'helpers/traits';
import { geocode, loadMapsApi } from 'helpers/utils';
import { colors } from 'helpers/variables';

import Button from './button';
import { FormikDropdown, FormikInput, Label } from './form-controls';
import Spinner from './spinner';
import { Headline, P } from './typography';
import ThemeContainer from './theme-container';

/** Theming */
const markerClustererTheme = {
    images: {
        sbf: clusterSBF,
        vielLeicht: clusterVielLeicht,
    },
    textColor: {
        sbf: colors.white,
        vielLeicht: colors.vielLeicht.secondary,
    },
};

const SpinnerCentered = styled(Spinner)`
    ${position('absolute', '50%', '50%')};
`;

const Wrapper = styled.div`
    height: 100%;
    position: relative;
`;

const MapContainer = styled.div`
    height: 0;
    padding-top: 100%;
    position: relative;
    width: 100%;

    ${mq.medium`
        padding-top: ${(1100 / 1920) * 100}%;
    `}
    ${mq.large`
        padding-top: ${(900 / 1920) * 100}%;
    `}

    ${props =>
        props.displayInViewportHeight &&
        mq.medium`
            height: 100%;
            padding: 0;
        `};
`;

const FormContainer = styled.div`
    background: white;
    padding: ${rem(20)};

    ${mq.medium`
        ${position('absolute', '50%', null, null, rem(30))};
        ${layerable(1)};
        transform: translateY(-50%);
        width: ${rem(300)};
    `};
`;

/** Wrapper im Info-Window */
const InfoWindowWrapper = styled.div`
    font-family: 'Futura LT W01 Book' !important;
    padding: 0.5em 0.25em;
`;

class Storelocator extends PureComponent {
    /**
     * Berechnet den Zoom anhand des Radius (Zoom Level 14 entspricht 1 Meile)
     * @return {Number} Zoom-Level
     */
    static radiusToZoom(radius) {
        return Math.round(14 - Math.log(radius) / Math.LN2);
    }

    /**
     * @type {Object} Optionen für den Marker-Clusterer
     */
    static markerClustererOptions = {
        averageCenter: true,
        styles: [
            {
                textSize: 18,
                height: 45,
                width: 45,
            },
        ],
    };

    /**
     * @type {Object} PropTypes
     */
    static propTypes = {
        stores: PropTypes.arrayOf(storeType).isRequired,
        formHeadline: PropTypes.string,
        displayInViewportHeight: PropTypes.bool,
        theme: PropTypes.shape({ main: PropTypes.string }).isRequired,
    };

    /**
     * @type {Object} Default Props
     */
    static defaultProps = {
        formHeadline: 'Händlersuche',
        displayInViewportHeight: false,
    };

    /**
     * @type {Object} State
     */
    state = {
        google: null,
    };

    /**
     * @type {Object} Ref auf DOM Element für die Google Map
     */
    mapEl = React.createRef();

    /**
     * @type {google.maps.Map}
     */
    map = null;

    /**
     * @type {google.maps.Circle}
     */
    radiusCircle = null;

    /**
     * Lädt die Maps API und die Daten bei Mount
     */
    componentDidMount() {
        loadMapsApi().then(google => {
            this.setState(
                {
                    google,
                },
                () => this.initMap(this.getTheme())
            );
        });
    }

    getTheme = () => {
        const {
            theme: { main: theme },
        } = this.props;

        return theme;
    };

    handleSubmit = (values, { setSubmitting, setFieldValue }) => {
        const { google } = this.state;
        const theme = this.getTheme();

        geocode(values.destination).then(({ status, results: [firstResult] }) => {
            if (status === 'OK' && firstResult) {
                const geocodedLocation = firstResult.geometry.location;
                setFieldValue('destination', firstResult.formatted_address);

                if (this.radiusCircle) {
                    this.radiusCircle.setMap(null);
                }

                this.radiusCircle = new google.maps.Circle({
                    strokeColor: colors[theme].primary,
                    strokeWeight: '2',
                    strokeOpacity: '0.8',
                    fillColor: colors[theme].secondary,
                    fillOpacity: '0.2',
                    center: geocodedLocation,
                    map: this.map,
                    radius: values.radius * 1000,
                });

                this.map.setCenter(geocodedLocation);
                this.map.setZoom(Storelocator.radiusToZoom(values.radius));
            }
            setSubmitting(false);
        });
    };

    /**
     * Initialisiert die Map
     */
    initMap(theme) {
        const { google } = this.state;
        const { stores } = this.props;

        const map = new google.maps.Map(this.mapEl.current, {
            center: new google.maps.LatLng(50.113146, 8.714566),
            zoom: 13,
            scrollwheel: false,
            mapTypeControl: false,
            styles,
        });

        const markers = [];
        const bounds = new google.maps.LatLngBounds();
        const infoWindow = new google.maps.InfoWindow();

        stores.forEach(store => {
            const marker = new google.maps.Marker({
                map,
                position: new google.maps.LatLng(store.lat, store.lng),
                icon: {
                    path:
                        'M17.500,0.137 C7.835,0.137 0.000,7.894 0.000,17.462 C0.000,17.698 0.007,17.942 0.019,18.191 C0.025,18.335 0.034,18.478 0.044,18.622 C0.050,18.713 0.057,18.807 0.065,18.900 C0.077,19.053 0.090,19.206 0.107,19.358 C1.558,33.353 17.500,60.123 17.500,60.123 C17.500,60.123 33.441,33.353 34.893,19.358 C34.910,19.206 34.922,19.053 34.934,18.900 C34.942,18.807 34.950,18.713 34.956,18.622 C34.966,18.478 34.975,18.336 34.981,18.191 C34.992,17.942 34.999,17.697 34.999,17.461 C34.999,7.894 27.164,0.137 17.500,0.137 Z',
                    anchor: new google.maps.Point(17, 60),
                    fillColor: colors[theme].primary,
                    strokeColor: colors[theme].secondary,
                    fillOpacity: 1,
                    scale: 1,
                    strokeWeight: 1,
                },
            });

            marker.addListener('click', () => {
                infoWindow.setContent(
                    ReactDOMServer.renderToStaticMarkup(
                        <ThemeContainer theme={{ main: theme }}>
                            <InfoWindowWrapper>
                                {store.name && (
                                    <Headline level="h5" gap="s">
                                        {store.name}
                                    </Headline>
                                )}
                                <P gap="m">
                                    {store.street && (
                                        <>
                                            {store.street} <br />
                                        </>
                                    )}
                                    {store.zip} {store.city}
                                </P>
                                <Button
                                    linkTo={`https://maps.google.com/maps?saddr=&daddr=${
                                        store.lat
                                    },${store.lng}`}
                                    layer={0}
                                    size="s"
                                >
                                    Route berechnen
                                </Button>
                            </InfoWindowWrapper>
                        </ThemeContainer>
                    )
                );
                infoWindow.open(map, marker);
            });

            markers.push(marker);
            bounds.extend(marker.getPosition());
        });

        map.fitBounds(bounds);
        this.map = map;

        // Theme Clusterer
        Storelocator.markerClustererOptions.styles[0].url = markerClustererTheme.images[theme];
        Storelocator.markerClustererOptions.styles[0].textColor =
            markerClustererTheme.textColor[theme];
        // eslint-disable-next-line no-new
        new MarkerClusterer(map, markers, Storelocator.markerClustererOptions);
    }

    /**
     * Rendert die Map
     * @returns {JSX}
     */
    render() {
        const { formHeadline, displayInViewportHeight } = this.props;

        return (
            <Wrapper>
                <MapContainer ref={this.mapEl} displayInViewportHeight={displayInViewportHeight}>
                    <SpinnerCentered>Lade Karte...</SpinnerCentered>
                </MapContainer>
                <FormContainer>
                    <Formik
                        initialValues={{ destination: '', radius: '5' }}
                        validate={values => {
                            const errors = {};
                            if (!values.destination) {
                                errors.destination = 'Bitte geben Sie Ihren Standort ein.';
                            }
                            return errors;
                        }}
                        onSubmit={this.handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Headline level="h2">{formHeadline}</Headline>
                                <Label htmlFor="destination" gap="s">
                                    Standort
                                </Label>
                                <Field
                                    type="text"
                                    name="destination"
                                    id="destination"
                                    placeholder="Ihr aktueller Standort"
                                    component={FormikInput}
                                    gap="l"
                                />
                                <Label htmlFor="radius" gap="s">
                                    Umkreis
                                </Label>
                                <Field
                                    name="radius"
                                    id="radius"
                                    component={FormikDropdown}
                                    gap="xl"
                                >
                                    <option value="5">5 km</option>
                                    <option value="10">10 km</option>
                                    <option value="20">20 km</option>
                                    <option value="50">50 km</option>
                                    <option value="100">100 km</option>
                                </Field>
                                <Button type="submit" disabled={isSubmitting}>
                                    Suche starten!
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </FormContainer>
            </Wrapper>
        );
    }
}

export default withTheme(Storelocator);
