import { ErrorMessage } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import { padding, rem } from 'polished';
import styled from 'styled-components';
import { gapable } from 'helpers/traits';
import { colors, fontSizes, borderRadiuses } from 'helpers/variables';
import Dropdown from './dropdown';

export const Input = styled.input`
    ${gapable()};
    background: ${colors.white};
    border: 1px solid ${colors.gray300};
    border-radius: ${borderRadiuses.s};
    box-sizing: border-box;
    color: ${colors.black};
    ${padding(rem(9), rem(10))};
    width: 100%;
`;

export const Checkbox = styled.input.attrs({
    type: 'checkbox',
})`
    ${gapable()};
    float: left;
    margin-top: 0.4em;
`;

export const Label = styled.label`
    display: block;
    font-size: ${fontSizes.s};
    font-weight: bold;
    ${gapable('none')};
`;

Label.CheckboxText = styled.span`
    display: block;
    font-weight: normal;
    margin-left: ${rem(25)};
`;

const ErrorText = styled.div`
    color: ${colors.alert};
    margin-top: ${rem(5)};
`;

const formikPropTypes = {
    field: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape({
        values: PropTypes.object,
    }).isRequired,
};

export const FormikInput = ({ field, form, ...props }) => (
    <>
        <Input {...field} {...props} />
        <ErrorMessage name={field.name} component={ErrorText} />
    </>
);

FormikInput.propTypes = {
    ...formikPropTypes,
};

export const FormikCheckbox = ({ field, form: { values }, label, ...props }) => (
    <>
        <Checkbox {...field} {...props} checked={values[field.name]} />
        {label}
        <ErrorMessage name={field.name} component={ErrorText} />
    </>
);

FormikCheckbox.propTypes = {
    ...formikPropTypes,
    label: PropTypes.node.isRequired,
};

export const FormikDropdown = ({ field, form, children, ...props }) => (
    <>
        <Dropdown {...field} {...props}>
            {children}
        </Dropdown>
        <ErrorMessage name={field.name} component={ErrorText} />
    </>
);

FormikDropdown.propTypes = {
    ...formikPropTypes,
    children: PropTypes.node.isRequired,
};
