import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Storelocator from 'ui-kit/storelocator';
import { nodesOf, storeType } from 'helpers/prop-types';

import Layout from '../../components/layout';

const StoreLocatorPage = ({ data }) => (
    <Layout description="Händlersuche" displayInViewportHeight>
        <Storelocator
            stores={data.allRetailerJson.edges.map(({ node }) => node)}
            displayInViewportHeight
        />
    </Layout>
);

StoreLocatorPage.propTypes = {
    data: PropTypes.shape({
        allRetailerJson: nodesOf(storeType),
    }).isRequired,
};

export const query = graphql`
    query {
        allRetailerJson {
            edges {
                node {
                    id
                    name
                    street
                    zip
                    city
                    lat
                    lng
                }
            }
        }
    }
`;

export default StoreLocatorPage;
